import { findExistingTicket } from './handlePhoneCall';
import { addComment } from 'src/actions/commentsActions';
import { activateTab, addTab } from 'src/actions/tabActionsRTK';
import {
  activateTicket,
  addEntityToCase,
  addTicket,
  createContent,
  fetchTicket,
  searchCustomerByDetail,
  updateSingleTicketCaseDetail,
  updateTicket
} from 'src/actions/ticketsActions';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import openExternalLink from 'src/handlers/openExternalLink';
import SocketInstance from 'src/realTimeNotifications';
import { StaticTabs } from 'src/types/TicketList';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import { startWorkingOn } from 'src/Utilities/workStatusParser';

import type { State } from 'src/types/initialState';
import type { Entity, Ticket } from 'src/types/Ticket';

export const handleIncomingPhoneCallElisaRing = (
  originalNumber: string,
  UID: string,
  ticketData: any,
  searchObject: any,
  serviceName: string | null
) => {
  return async (dispatch: any, getState: () => State) => {
    const { userData, ticketTypes, ticketListTabs } = getState();
    const { tickets } = ticketListTabs[StaticTabs.MAIN_VIEW];
    const normalizedPhoneNumber = normalizePhoneNumber(originalNumber);

    ticketData.originalContact = normalizedPhoneNumber;

    let ticket = (await findExistingTicket({
      direction: ticketData.direction,
      normalizedPhoneNumber,
      taskType: ticketData.taskType,
      tickets
    })) as Ticket;
    const ticketAlreadyExists = !!ticket;

    if (ticket) {
      await dispatch(fetchTicket({ id: ticket.id, shouldActivateTicket: false }));
      await dispatch(updateTicket({ id: ticket.id, ticket: { status: 'doing' } }));
    } else {
      ticket = await dispatch(createContent(ticketData));
    }

    if (ticket) {
      // now with ticket data create some tabs
      SocketInstance.joinRoom(ticket.id);
      dispatch(addTicket(ticket));
      dispatch(addTab({ id: ticket.id, type: 'tickets' }));
      dispatch(activateTicket(ticket.id));
      dispatch(activateTab(ticket.id));
      await TicketsApi.startWorkingOn(...startWorkingOn(UID, ticket.id));

      await dispatch(
        addComment(ticket.id, {
          content: `[original_phonenumber:${normalizedPhoneNumber}]`,
          channel: 2,
          sendAsMail: false
        })
      );

      if (serviceName) {
        dispatch(updateSingleTicketCaseDetail(ticket.id, 'ocServiceName', serviceName, 'CaseDetails'));
      }

      if (ticketData.metaData?.elisaRingCallId)
        dispatch(
          updateSingleTicketCaseDetail(
            ticket.id,
            'elisaRingCallId',
            ticketData.metaData?.elisaRingCallId,
            'CaseDetails'
          )
        );

      // search for entity
      const entityResults: Entity[] = await dispatch(
        searchCustomerByDetail({
          ...searchObject,
          taskType: ticketData.taskType
        })
      );

      if (!ticketAlreadyExists) {
        if (entityResults.length === 0) {
          // add entity based on originalNumber, ticketType etc.
          dispatch(
            addEntityToCase(ticket.id, {
              taskType: ticketData.taskType,
              ...searchObject
            })
          );
        } else {
          // add entity to case
          dispatch(
            addEntityToCase(ticket.id, {
              _id: entityResults[0]._id,
              taskType: ticket.taskType,
              _type: entityResults[0]._type
            })
          );
        }
      }

      if (entityResults.length > 1) {
        // add comment multiple customers
        dispatch(
          addComment(ticket.id, {
            content: '[had_multiple_entities]'
          })
        );
      }

      if (FeatureFlags.isFlagOn('OPEN_EXTERNAL_LINK_ON_PHONE_CALL')) {
        dispatch(
          openExternalLink({
            userData,
            ticketTypes,
            task: ticket,
            entity: entityResults[0] || searchObject,
            normalizedPhoneNumber
          })
        );
      }
    }
  };
};
